import React, { useState, useEffect } from "react";
import axios from "axios";

function App() {
  const [user, setUser] = useState(null);
  const [webApp, setWebApp] = useState(null);
  const [currentView, setCurrentView] = useState("menu");
  const [tasks, setTasks] = useState([]);
  const [userReward, setUserReward] = useState(0);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;

      tg.ready();
      tg.expand();
      tg.enableClosingConfirmation();

      setWebApp(tg);
      validateWebApp(tg);
    }
  }, []);

  const validateWebApp = async (tg) => {
    try {
      const response = await axios.post("/api/webapp/validate-webapp", {
        initData: tg.initData,
      });

      setUser(response.data.user);
      fetchUserReward(response.data.user.id);
    } catch (err) {
      console.error("Validasi WebApp gagal", err);
    }
  };

  const fetchUserReward = async (userId) => {
    try {
      const response = await axios.get(`/api/users/${userId}/reward`);
      setUserReward(response.data.balance);
    } catch (err) {
      console.error("Gagal mengambil reward", err);
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await axios.get("/api/tasks/videos");
      setTasks(response.data);
      setCurrentView("videos");
    } catch (err) {
      webApp.showAlert("Gagal memuat video");
    }
  };

  const fetchAds = async () => {
    try {
      const response = await axios.get("/api/tasks/ads");
      setTasks(response.data);
      setCurrentView("ads");
    } catch (err) {
      webApp.showAlert("Gagal memuat iklan");
    }
  };

  const completeTask = async (taskId) => {
    try {
      const response = await axios.post("/api/users/complete-task", {
        userId: user.id,
        taskId,
      });

      webApp.showAlert(`Tugas selesai! Reward: ${response.data.reward}`);
      fetchUserReward(user.id);
    } catch (err) {
      webApp.showAlert("Gagal menyelesaikan tugas");
    }
  };

  const renderMenu = () => (
    <div>
      <h2>Selamat Datang, {user.first_name}</h2>
      <p>Total Reward: {userReward} coin</p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button
          onClick={fetchVideos}
          style={{
            padding: "10px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Tonton Video
        </button>

        <button
          onClick={fetchAds}
          style={{
            padding: "10px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Lihat Iklan
        </button>

        <button
          onClick={() => setCurrentView("reward")}
          style={{
            padding: "10px",
            backgroundColor: "#ffc107",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Cek Reward
        </button>
      </div>
    </div>
  );

  const renderVideos = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        style={{
          marginBottom: "10px",
          padding: "5px 10px",
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        Kembali
      </button>

      <h3>Daftar Video</h3>
      {tasks.map((video) => (
        <div
          key={video._id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
          }}
        >
          <h4>{video.title}</h4>
          <p>Reward: {video.reward} coin</p>
          <button
            onClick={() => completeTask(video._id)}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "8px 15px",
              borderRadius: "5px",
            }}
          >
            Tonton
          </button>
        </div>
      ))}
    </div>
  );

  const renderAds = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        style={{
          marginBottom: "10px",
          padding: "5px 10px",
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        Kembali
      </button>

      <h3>Daftar Iklan</h3>
      {tasks.map((ad) => (
        <div
          key={ad._id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
          }}
        >
          <h4>{ad.title}</h4>
          <p>Reward: {ad.reward} coin</p>
          <button
            onClick={() => completeTask(ad._id)}
            style={{
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              padding: "8px 15px",
              borderRadius: "5px",
            }}
          >
            Lihat Iklan
          </button>
        </div>
      ))}
    </div>
  );

  const renderReward = () => (
    <div>
      <button
        onClick={() => setCurrentView("menu")}
        style={{
          marginBottom: "10px",
          padding: "5px 10px",
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        Kembali
      </button>

      <h3>Detail Reward</h3>
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        <p>Total Reward Anda: {userReward} coin</p>
        <p>Riwayat Reward:</p>
        {/* Tambahkan logika untuk menampilkan riwayat reward */}
      </div>
    </div>
  );

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        padding: "15px",
        backgroundColor: webApp?.themeParams?.bg_color || "#f4f4f4",
        color: webApp?.themeParams?.text_color || "black",
        minHeight: "100vh",
      }}
    >
      {currentView === "menu" && renderMenu()}
      {currentView === "videos" && renderVideos()}
      {currentView === "ads" && renderAds()}
      {currentView === "reward" && renderReward()}
    </div>
  );
}

export default App;
